import { ActionReducerMap, createSelector } from '@ngrx/store';
import * as fromUi from './ui/ui.reducer';
import * as fromAuthUser from './auth/auth-user.reducer';


export interface AppState {
  AuthUser: fromAuthUser.AuthUserState;
  Ui: fromUi.UiState;
}

export const coreReducers: ActionReducerMap<AppState> = {
  AuthUser: fromAuthUser.authUserReducer,
  Ui: fromUi.uiReducer,
};

// UI
export const selectUiState = (state: AppState): (fromUi.UiState) => state.Ui;

export const selectUi_IsMenuClose = createSelector(
  selectUiState,
  (state: fromUi.UiState) => state.isMenuClose
);

export const selectUi_IsMobile = createSelector(
  selectUiState,
  (state: fromUi.UiState) => state.isMobile
);


// USER
export const selectAuthUserState = (state: AppState) => state.AuthUser;

export const selectAuthUser_User = createSelector(
  selectAuthUserState,
  (state: fromAuthUser.AuthUserState) => state.user
);

export const selectAuthUser_IsLogging = createSelector(
  selectAuthUserState,
  (state: fromAuthUser.AuthUserState) => state.isLoading
);

export const selectAuthUser_IsError = createSelector(
  selectAuthUserState,
  (state: fromAuthUser.AuthUserState) => state.error
);


