import { Component, OnInit } from '@angular/core';
import { authUser_LoadAuthUserState } from '@core/ngrx/auth/auth-user.actions';
import { AppState } from '@core/ngrx/core.reducer';
import { Store } from '@ngrx/store';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Prenotazioni Questura';

  constructor(
    private _translateSvc: TranslateService,
    private _store: Store<AppState>

  ) {
    this._translateSvc.addLangs(['it', 'ru', 'uk']);

    this._translateSvc.setDefaultLang('it');

  }

  ngOnInit(): void {
    if (localStorage.getItem('AuthUserState')) {
      this._store.dispatch(authUser_LoadAuthUserState());
    }
  }
}
