import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../ngrx/core.reducer';
import { authUser_LoggingOutUser } from '../ngrx/auth/auth-user.actions';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private _store: Store<AppState>,
  ) { }

  // tslint:disable-next-line: no-any
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const token = localStorage.getItem('token');

    if (token !== null) {
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + token
        }
      });
    }

    return next.handle(request).pipe(
      tap({
        next: () => { },

        // tslint:disable-next-line: no-any
        error: (error: any) => {
          if (error instanceof HttpErrorResponse && error.status === 401) {
              this._store.dispatch(authUser_LoggingOutUser());
          }
        }
      }));

  }
}
