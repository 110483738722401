import { Action, createReducer, on } from '@ngrx/store';
import { PlaceReadDto } from '@rt-library';
import {
  authUser_LoadAuthUserState,
  authUser_LoggingInUser,
  authUser_LoggingOutUser,
  authUser_RemoveError,
  authUser_SetError,
  authUser_StoreUser,
  authUser_UpdateUser
} from './auth-user.actions';

export interface AuthUserState {
  user: PlaceReadDto | undefined;
  isLoading: boolean;
  error: boolean;
}

const initialState: AuthUserState = {
  user: undefined,
  isLoading: false,
  error: false,
};

const reducer = createReducer(
  initialState,
  on(authUser_StoreUser, (state, { user }) => saveState({
    ...state,
    user,
    isLoading: false,
  })),

  on(authUser_SetError, state => saveState({
    ...state,
    isLoading: false,
    error: true
  })),

  on(authUser_LoggingInUser, state => saveState({
    ...state,
    isLoading: true,
    error: false
  })),

  on(authUser_UpdateUser, (state, { user }) => saveState({
    ...state,
    user
  })),

  on(authUser_LoggingOutUser, state => saveState({
    ...initialState,
  })),

  on(authUser_RemoveError, state => saveState({
    ...state,
    error: false
  })),

  on(authUser_LoadAuthUserState, state => {

    if (localStorage.getItem('AuthUserState') !== null) {

      return JSON.parse(localStorage.getItem('AuthUserState') || '');
    }
    saveState({ ...initialState, error: false });
    return { ...initialState, error: false };

  }),
);

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function authUserReducer(state: AuthUserState | undefined, action: Action): AuthUserState {
  return reducer(state, action);
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
function saveState(state: AuthUserState): AuthUserState {
  localStorage.setItem('AuthUserState', JSON.stringify(state));
  return state;
}



