<div class="-container">
  <div class="-content">

    <div class="d-flex align-items-center">
      <a href="/">
        <div class="d-flex align-items-center -logo-container">
          <img src="/assets/images/stemma-polizia-stato.svg" class="-logo-img">
          <div class="color-white">
            <div class="--ff-anton -logo-text" translate>Questura di Padova</div>
            <div class="body2 pt-2" translate>Ufficio immigrazione</div>
          </div>
        </div>
      </a>

      <div class="d-flex align-items-center -box-emergency -no-mobile ms-4">
        <img src="assets/images/ua-flag-2.png" class="-flag-img">
        <div class="color-white -flag-text --ff-anton">UA EMERGENCY</div>
      </div>
    </div>


    <div class="d-flex align-items-center">
      <div class="-language-menu" *ngIf="!currentUser">
        <div class="-menu-link -language mx-2 menu-text" (click)="handleLanguage('it')"
          [class.active]="currentLang === 'it'" translate>IT
        </div>
        <div class="-menu-link -language mx-2 menu-text" (click)="handleLanguage('ru')"
          [class.active]="currentLang === 'ru'" translate>RU
        </div>
        <div class="-menu-link -language mx-2 menu-text" (click)="handleLanguage('uk')"
          [class.active]="currentLang === 'uk'" translate>UK
        </div>
      </div>

      <button mat-icon-button [matMenuTriggerFor]="menu" *ngIf="currentUser">
        <mat-icon class="color-white">more_vert</mat-icon>
      </button>

      <mat-menu #menu="matMenu">
        <button (click)="logout()" mat-menu-item>
          <mat-icon class="material-icons-outlined">logout</mat-icon>
          Log Out
        </button>
      </mat-menu>
    </div>
  </div>

  <!-- <div class="color-white d-flex align-items-center mt-2 justify-content-center  ">
    <div class="--ff-anton -logo-text" translate>Questura di Padova</div>
    <div class="mx-2"></div>
    <div class="body2 pt-1" translate>Ufficio immigrazione</div>
  </div> -->



</div>
<div class="w-100 -banner-mobile align-items-center justify-content-center">
  <div class="-flag-text --ff-anton text-center">UA EMERGENCY</div>
</div>