import { Component, OnDestroy, OnInit } from '@angular/core';
import { authUser_LoggingOutUser } from '@core/ngrx/auth/auth-user.actions';
import { AppState, selectAuthUser_User } from '@core/ngrx/core.reducer';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { PlaceReadDto } from '../../../../../../../../library/dto/place/places.dto';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit, OnDestroy {

  destroy$ = new Subject<boolean>();

  currentLang: string = 'it';
  currentUser: PlaceReadDto | undefined;

  constructor(
    private _translateSvc: TranslateService,
    private _store: Store<AppState>
  ) { }

  ngOnInit(): void {

    //handle language
    const langSaved = localStorage.getItem('currentLang');
    
    if (langSaved) {
      this.handleLanguage(langSaved);
    } else {
      this.currentLang = this._translateSvc.defaultLang;
    }

    this._listenForLoggedUser();
  }


  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }


  handleLanguage(lang: string): void {

    
    if (lang === this.currentLang) {
      return;
    }

    this._translateSvc.use(lang)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        res => {
          localStorage.setItem('currentLang', this._translateSvc.currentLang);
          this.currentLang = this._translateSvc.currentLang;
        },
        error => {
        }
      );
  }

  logout(): void {
    this._store.dispatch(authUser_LoggingOutUser());
  }

  private _listenForLoggedUser(): void {

    this._store.select(selectAuthUser_User)
      .subscribe(
        async user => {
          if (user?._id) {
            this.currentUser = user;
            console.log(this.currentUser);
            
          }
        }
      );
  }

}
