import { createReducer, on, Action, ActionReducer } from '@ngrx/store';
import { ui_IsMobileDisable, ui_IsMobileEnable, ui_MenuClose, ui_MenuOpen, ui_MenuToggle } from './ui.actions';

export interface UiState {
  isMenuClose: boolean;
  isMobile: boolean;
}

const initialState: UiState = {
  isMenuClose: true,
  isMobile: true
};

const reducer = createReducer(
  initialState,
  on(ui_MenuOpen, state => {
    const newState = { ...state, isMenuClose: false };
    saveState(newState);
    return newState;
  }),
  on(ui_MenuClose, state => {
    const newState = { ...state, isMenuClose: true };
    saveState(newState);
    return newState;
  }),
  on(ui_MenuToggle, state => {
    const newState = { ...state, isMenuClose: !state.isMenuClose };
    saveState(newState);
    return newState;
  }),
  on(ui_IsMobileEnable, state => {
    const newState = { ...state, isMobile: true };
    saveState(newState);
    return newState;
  }),
  on(ui_IsMobileDisable, state => {
    const newState = { ...state, isMobile: false };
    saveState(newState);
    return newState;
  }),
);

// tslint:disable-next-line: typedef
export function uiReducer(state: UiState | undefined, action: Action) {
  return reducer(state, action);
}

function saveState(state: UiState): void {
  localStorage.setItem('UiState', JSON.stringify(state));
}
