import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState, selectAuthUser_User } from '../ngrx/core.reducer';
import { take, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private _store: Store<AppState>,
    private _router: Router
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

    return this._store.select(selectAuthUser_User)
      .pipe(
        take(1),
        map(user => {

          if (user?._id) {
            return true;
          }

          this._router.navigate(['login']);
          return false;
        })
      );
  }
}
