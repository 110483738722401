<div class="-footer">
  <div class="main-container py-3 px-4">
    <div class="-content body1">

      <!-- COL LEFT-->
      <div class="-col-left color-primary-200">
        <span translate>Realizzato da</span>
        <a href="https://raintonic.com/" target="_blank" class="-footer-link">
          Rain Tonic Srl
        </a>
      </div>

      <!-- COL CENTER -->
      <div class="-col-center flex-grow-1">
        <div class="--ff-anton --ls-02 -logo-text  text-center">QUESTURA DI PADOVA</div>
        <div class="text-center -collaboration">
          <span translate class="color-primary-200">In collaborazione con</span>
          <a href="https://www.unipd.it/" target="_blank" class="-footer-link color-unipd">
            {{ 'Università degli Studi di Padova' | translate }}
          </a>
        </div>
      </div>

      <!-- COL RIGHT -->
      <div class="-col-right">
        <a routerLink="/privacy-policy" target="_blank" class="-footer-link body2" translate>Privacy Policy</a>
      </div>

    </div>

  </div>
</div>