import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IRtWrapBase } from '@rt-codebase/data-service/interfaces/i-rt-wrap-base.interface';
import { AuthDto } from '@rt-library';

@Injectable({
  providedIn: 'root'
})
export class AuthUserService {

  urlApi: string = environment.urlApi + '/places';

  constructor(
    private _http: HttpClient,
  ) { }

  login(username: string, password: string): Observable<IRtWrapBase<AuthDto>> {

    return this._http.post<IRtWrapBase<AuthDto>>(this.urlApi + '/login', { username, password });
  }
}

