import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cookie-popup',
  templateUrl: './cookie-popup.component.html',
  styleUrls: ['./cookie-popup.component.scss']
})
export class CookiePopupComponent implements OnInit {

  showCookiePopup: boolean = true;

  constructor() { }

  ngOnInit(): void {
    // check local storage to show cookie
    if (localStorage.getItem('showCookiePopup')) {
      this.showCookiePopup = localStorage.getItem('showCookiePopup') === 'true' ? true : false;
    } 
  }

  closeCookie(): void {
    // save cookie in local storage
    this.showCookiePopup = false;
    localStorage.setItem('showCookiePopup', 'false');


  }

}
