import { createAction } from '@ngrx/store';

const actionsPrefix = '[UI] ';

export const ui_MenuOpen = createAction(
  actionsPrefix + 'Menu Open',
);
export const ui_MenuClose = createAction(
  actionsPrefix + 'Menu Close',
);
export const ui_MenuToggle = createAction(
  actionsPrefix + 'Menu Toggle',
);


export const ui_IsMobileEnable = createAction(
  actionsPrefix + 'IsMobile Enable',
);
export const ui_IsMobileDisable = createAction(
  actionsPrefix + 'IsMobile Disable',
);




