import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, exhaustMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { authUser_LoggingInUser, authUser_LoggingOutUser, authUser_SetError, authUser_StoreUser } from './auth-user.actions';
import { AuthUserService } from '../../data-services/auth-user.service';

@Injectable()
export class AuthUserEffects {

  loggingIn$ = createEffect(() => this._actions$.pipe(
    ofType(authUser_LoggingInUser),
    exhaustMap((action: any) => this._authSvc.login(action.username, action.password).pipe(
      map(res => {
        localStorage.setItem('token', res.data.token);
        return authUser_StoreUser({ user: res.data.place });
      }),
      catchError((error) => of(authUser_SetError()))
    )),
  ));

  loggingOut$ = createEffect(() => this._actions$.pipe(
    ofType(authUser_LoggingOutUser),
    tap(async () => {
      localStorage.removeItem('token');
      await this._router.navigate(['/']);
      location.reload();
    })
  ), { dispatch: false });

  constructor(
    private _actions$: Actions,
    private _authSvc: AuthUserService,
    private _router: Router,
  ) {
  }

}
