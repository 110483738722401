import { createAction, props } from '@ngrx/store';
import { PlaceReadDto } from '@rt-library';



const actionsPrefix = '[AuthUser] ';

export const authUser_LoggingInUser = createAction(
  actionsPrefix + 'Logging in user',
  props<{ username: string; password: string }>(),
);

export const authUser_FetchingUser = createAction(
  actionsPrefix + 'Storing User',
  props<{ idUser: string }>(),
);

export const authUser_StoreUser = createAction(
  actionsPrefix + 'Store User',
  props<{ user: PlaceReadDto }>(),
);

export const authUser_UpdateUser = createAction(
  actionsPrefix + 'Update User',
  props<{ user: PlaceReadDto }>(),
);

export const authUser_LoggingOutUser = createAction(
  actionsPrefix + 'Logging Out User'
);

export const authUser_SetError = createAction(
  actionsPrefix + 'Set error',
);

export const authUser_RemoveError = createAction(
  actionsPrefix + 'Remove error',
);

export const authUser_LoadAuthUserState = createAction(
  actionsPrefix + 'Load Auth User State',
);
