import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CookiePopupComponent } from './components/cookie-popup/cookie-popup.component';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [
    CookiePopupComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    TranslateModule.forChild()
  ],
  exports: [
    CookiePopupComponent
  ]
})
export class CookiePopupModule { }
